<template>
  <draggable
    v-model="draggableList"
    :disabled="!allows_crud"
    tag="b-tbody"
    handle=".handle"
  >
    <template v-if="draggableList.length > 0">
      <b-tr v-for="evaluation in draggableList" :key="evaluation.id">
        <b-td
          class="text-center handle"
          :class="{ grabbable: allows_crud }"
          v-if="allows_crud"
          ><b-icon v-if="allows_crud" icon="arrows-expand" scale="1.2"></b-icon
        ></b-td>
        <b-td class="text-center">
          <span
            v-if="
              evaluation.evaluation != null &&
              institution &&
              institution.kl_assessment &&
              user &&
              ((!user.groups.includes(6) && !user.groups.includes(7)) ||
                user.is_superuser)
            "
            class="div-href"
            @click="redirectToEvaluation(evaluation.evaluation)"
          >
            {{ evaluation.name }}
          </span>
          <span v-else>
            {{ evaluation.name }}
          </span>
        </b-td>
        <b-td>
          <div
            class="rich-text-content description-evaluation px-2"
            v-html="evaluation.description"
          ></div>
        </b-td>
        <b-td class="text-center">
          {{
            epm_evaluation_types.find((x) => x.id == evaluation.type)
              ? epm_evaluation_types.find((x) => x.id == evaluation.type).value
              : "N/A"
          }}</b-td
        >
        <b-td class="text-center">
          <span v-if="evaluation.type == 4">-</span>
          <span v-else> {{ evaluation.weighing }}% </span>
        </b-td>
        <b-td
          v-if="institution && institution.show_evaluation_criteria_macro"
          :class="
            evaluation.evaluation_criterias_macro.length > 0
              ? 'text-justify'
              : 'text-center'
          "
        >
          <template v-if="evaluation.evaluation_criterias_macro.length > 0">
            <template
              v-for="evaluation_criteria in evaluationCriteriasMacrosFilter"
            >
              <span
                :key="evaluation_criteria.id"
                v-if="
                  evaluation.evaluation_criterias_macro.includes(
                    evaluation_criteria.id
                  )
                "
              >
                <span style="position: absolute">
                  {{ evaluation_criteria.order }}
                </span>
                <SentenceContainer
                  style="padding-left: 5px; padding-top: 2px"
                  :Sentence="evaluation_criteria"
                  :boundedTextContainer="false"
                ></SentenceContainer>
              </span>
            </template>
          </template>
          <template v-else> N/A </template>
        </b-td>
        <b-td
          :class="
            evaluationCriteriasMicros.filter((x) =>
              evaluation.evaluation_criterias_micro.includes(x.id)
            ).length > 0
              ? 'text-justify'
              : 'text-center'
          "
        >
          <template
            v-if="
              evaluationCriteriasMicros.filter((x) =>
                evaluation.evaluation_criterias_micro.includes(x.id)
              ).length > 0
            "
          >
            <template v-for="study_unit in filterECMicro">
              <strong
                :key="study_unit.id"
                v-if="
                  study_unit.options.filter((x) =>
                    evaluation.evaluation_criterias_micro.includes(x.id)
                  ).length > 0
                "
                >{{ study_unit.name }}</strong
              >
              <div
                class="ec-badge-container"
                :key="'ec-container' + study_unit.id"
              >
                <div
                  v-for="evaluation_criteria in study_unit.options"
                  :key="study_unit.id + ':' + evaluation_criteria.id"
                >
                  <div
                    v-if="
                      evaluation.evaluation_criterias_micro.includes(
                        evaluation_criteria.id
                      )
                    "
                    :key="'div' + study_unit.id + ':' + evaluation_criteria.id"
                    class="text-center"
                  >
                    <b-badge
                      :id="`ec-badge-${evaluation.id}-${study_unit.id}-${evaluation_criteria.id}`"
                      class="ec-micro-badge"
                      v-b-tooltip.v-secondary.noninteractive="
                        `${evaluation_criteria.full_sentence}`
                      "
                    >
                      {{
                        study_unit.competence_unit_order != 999
                          ? study_unit.competence_unit_order + "."
                          : ""
                      }}{{ study_unit.order }}.{{ evaluation_criteria.order }}
                    </b-badge>
                  </div>
                </div>
              </div>
            </template>
          </template>
          <template v-else><span> N/A </span> </template>
        </b-td>
        <b-td class="td-btn-wrapper" v-if="allows_crud">
          <ButtonWrapper :item_id="evaluation.id">
            <template v-slot:btn-slot>
              <b-button
                v-if="allows_crud"
                size="sm"
                fill="white"
                variant="none"
                v-b-tooltip.v-secondary.noninteractive.top="
                  'Editar Recursos Bibliográficos'
                "
                @click="
                  $bvModal.show(
                    `epm-bibliographic-resource-modal-${evaluation.id}`
                  )
                "
              >
                <b-icon icon="book"></b-icon>
              </b-button>
              <button-edit
                v-if="allows_crud"
                fill="white"
                v-b-tooltip.v-secondary.noninteractive.top="
                  'Editar Actividad/Evaluación'
                "
                @click="$bvModal.show(`edit-evaluation-modal-${evaluation.id}`)"
              ></button-edit>
              <button-delete
                v-b-tooltip.v-secondary.noninteractive.top="
                  'Eliminar Actividad/Evaluación'
                "
                @click="deleteEvaluation(evaluation.id, evaluation.type)"
              ></button-delete>
            </template>
          </ButtonWrapper>
          <b-modal
            :id="`epm-bibliographic-resource-modal-${evaluation.id}`"
            title="Editar Recursos Bibliográficos"
            size="xl"
            hide-footer
          >
            <EPMEvaluationBibliographicResource
              :epm_evaluation_id="evaluation.id"
              :ep_matter="matter"
              :allows_crud="allows_crud"
            ></EPMEvaluationBibliographicResource>
          </b-modal>
          <b-modal
            :id="`edit-evaluation-modal-${evaluation.id}`"
            title="Editar Actividad/Evaluación"
            size="xl"
            hide-footer
          >
            <EPMatterProgramEvaluationForm
              :egress_profile_matter_id="egress_profile_matter_id"
              :Evaluation="evaluation"
              :EvaluationSituations="evaluation_situations"
              :evaluation_list="profile_matters_evaluations"
              :EPMatEvaStuEnvTimAll="ep_mat_eva_stu_env_tim_all"
              @fetch_ep_mat_eva_stu_env_tim_all="
                () => $emit('fetch_ep_mat_eva_stu_env_tim_all')
              "
              @updated="updatedEvaluation"
              @created_support_resource="createdSupportResource"
            >
            </EPMatterProgramEvaluationForm>
          </b-modal>
        </b-td>
      </b-tr>
    </template>
    <b-tr v-else>
      <b-td class="text-center" colspan="8"
        >No hay actividades o evaluaciones para mostrar.</b-td
      >
    </b-tr>
  </draggable>
</template>

<script>
import draggable from "vuedraggable";
import * as names from "@/store/names";
import { mapGetters } from "vuex";
export default {
  name: "MatterProgramEvaluationDraggable",
  components: {
    draggable,
    EPMatterProgramEvaluationForm: () =>
      import("./EPMatterProgramEvaluationForm.vue"),
    EPMEvaluationBibliographicResource: () =>
      import("./EPMEvaluationBibliographicResource.vue"),
    SentenceContainer: () => import("@/components/reusable/SentenceContainer"),
    ButtonWrapper: () => import("@/components/reusable/ButtonWrapper"),
  },
  props: {
    allows_crud: {
      type: Boolean,
    },
    mattersEvaluations: {
      type: Array,
    },
    learning_experience: {
      type: [Number, null],
      default: null,
    },
    egress_profile_matter_id: {
      type: Number,
      required: true,
    },
    matter_id: {
      type: Number,
      required: true,
    },
    EvaluationSituations: {
      type: Array,
    },
    EPMatEvaStuEnvTimAll: {
      type: Array,
    },
  },
  data() {
    return {
      profile_matters_evaluations: this.mattersEvaluations,
      evaluation_situations: this.EvaluationSituations,
      ep_mat_eva_stu_env_tim_all: this.EPMatEvaStuEnvTimAll,
    };
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      institution: "getInstitution",
      egress_profiles_matters: names.EGRESS_PROFILES_MATTERS,
      studyEnvironments: names.STUDY_ENVIRONMENTS,
      evaluationCriteriasMacros: names.EVALUATION_CRITERIAS_MACROS,
      evaluationCriteriasMicros: names.EVALUATION_CRITERIAS,
      studyUnits: names.STUDY_UNITS,
      epm_evaluation_types: names.EPM_EVALUATION_TYPES,
      competence_units: names.COMPETENCE_UNITS,
    }),
    draggableList: {
      get() {
        let list = this.profile_matters_evaluations.filter(
          (x) => x.learning_experience == this.learning_experience
        );
        list.sort(function (a, b) {
          if (a.order < b.order) return -1;
          if (a.order > b.order) return 1;
          return 0;
        });
        let order = 1;
        list.forEach((element) => {
          if (element.order != order) element.order = order;
          order += 1;
        });
        return list;
      },
      set(list) {
        let order = 1;
        list.forEach((element) => {
          if (element.order != order) {
            element.order = order;
            if (!isNaN(element.id)) {
              this.updatedEvaluation(element);
            }
          }
          order += 1;
        });
      },
    },
    matter() {
      return this.egress_profiles_matters.find(
        (x) => x.id == this.egress_profile_matter_id
      );
    },
    evaluationCriteriasMacrosFilter() {
      return this.evaluationCriteriasMacros
        .filter((x) => x.matter == this.matter_id)
        .sort(function (a, b) {
          if (a.matter > b.matter) return 1;
          if (a.matter < b.matter) return -1;
          if (a.order > b.order) return 1;
          if (a.order < b.order) return -1;
          return 0;
        });
    },
    studyUnitsList() {
      if (!this.matter_id) return [];
      return this.studyUnits
        .filter((x) => x.matter == this.matter_id)
        .sort(function (a, b) {
          if (a.order > b.order) return 1;
          if (a.order < b.order) return -1;
          return 0;
        });
    },
    filterECMicro() {
      let list = [];
      this.studyUnitsList.forEach((study_unit) => {
        const evaluation_criterias = this.evaluationCriteriasMicros.filter(
          (x) => x.study_unit == study_unit.id
        );
        const competence_unit = this.competence_units.find(
          (c) => c.id == study_unit.temp_competence_unit
        );
        if (evaluation_criterias.length > 0)
          list.push({
            id: study_unit.id,
            name: String(
              "N°" +
                (competence_unit ? competence_unit.order + "." : "") +
                study_unit.order +
                ": " +
                study_unit.name
            ),
            order: study_unit.order,
            competence_unit_order: competence_unit
              ? competence_unit.order
              : 999,
            options: this.evaluationCriteriasMicros
              .filter((x) => x.study_unit == study_unit.id)
              .sort(function (a, b) {
                if (a.order > b.order) return 1;
                if (a.order < b.order) return -1;
                return a.full_sentence
                  .toLowerCase()
                  .localeCompare(b.full_sentence.toLowerCase());
              }),
          });
      });
      return list.sort(function (a, b) {
        if (a.competence_unit_order > b.competence_unit_order) return 1;
        if (a.competence_unit_order < b.competence_unit_order) return -1;
        if (a.order > b.order) return 1;
        if (a.order < b.order) return -1;
        return 0;
      });
    },
  },
  methods: {
    createdSupportResource(response) {
      this.$emit("created_support_resource", response);
    },
    redirectToEvaluation(evaluation_id, instrument_id = null) {
      this.$router.push({
        name: "NewEvaluationView",
        params: {
          evaluation_id: evaluation_id,
          instrument_id: instrument_id,
        },
      });
    },
    createdNewEvaluation(response, tests_list) {
      this.$store
        .dispatch(names.POST_NEW_EVALUATION, {
          title: response.name,
          description: response.description,
          weighing: response.weighing,
          visible_by_evaluatees: false,
          parent_evaluation: null,
          files: [],
        })
        .then((new_evaluation) => {
          this.$store.commit(
            "MUTATE_CHANGE_NEW_EVALUATION_BY_EP_MATTER_EVALUATION",
            {
              new_evaluation_id: new_evaluation.id,
              egress_profile_matter_evaluation_id: response.id,
            }
          );
          this.$restful
            .Post("/evaluations2/evaluation-scope-default/", {
              scope: 2,
              scope_id: this.egress_profile_matter_id,
              evaluation: new_evaluation.id,
            })
            .then((evaluation_scope) => {
              this.$store.commit(
                "MUTATE_CHANGE_NEW_EVALUATION_SCOPE_BY_NEW_EVALUATION",
                evaluation_scope
              );
              response.evaluation = new_evaluation.id;
              this.$restful
                .Patch(
                  `/teaching/egress-profile-matter-evaluation/${response.id}/`,
                  { evaluation: new_evaluation.id }
                )
                .then(() => {
                  this.bulkCreateTest(tests_list, new_evaluation);
                });
              if (
                this.profile_matters_evaluations.find(
                  (x) => x.id == response.id
                )
              ) {
                const index = this.profile_matters_evaluations.findIndex(
                  (x) => x.id == response.id
                );
                if (index != -1) {
                  this.profile_matters_evaluations.splice(index, 1, response);
                  this.$bvModal.hide(`edit-evaluation-modal-${response.id}`);
                }
              } else this.profile_matters_evaluations.push(response);
              // evaluations2: sumativa 1, diagnóstica 2, formativa 3
              // evaluation_type:  diagnóstica 1, formativa 2, sumativa 3, actividad 4, transversal 5
              let evaluation_type = null;
              if (response.type == 1) evaluation_type = 2;
              else if ([2, 4].includes(response.type)) evaluation_type = 3;
              else if ([3, 5].includes(response.type)) evaluation_type = 1;
              else evaluation_type = 3;
              this.saveEvaluationTypeDefault(new_evaluation, {
                id: new_evaluation.evaluation_type_default,
                type: evaluation_type,
                evaluation: new_evaluation.id,
              });
            });
        });
    },
    // createdEvaluation(evaluation, tmp_ep_mat_eva_stu_env_tim_alls) {
    //   this.$restful
    //     .Post(`/teaching/egress-profile-matter-evaluation/`, evaluation)
    //     .then((response) => {
    //       if (response.evaluation == null) {
    //         this.createdNewEvaluation(response);
    //       } else this.profile_matters_evaluations.push(response);
    //       this.createdMatStuEnvTimAlls(
    //         response,
    //         tmp_ep_mat_eva_stu_env_tim_alls
    //       );
    //       this.$bvModal.hide(
    //         `add-evaluation-modal-${this.egress_profile_matter_id}`
    //       );
    //     });
    // },
    async createdMatStuEnvTimAlls(evaluation, tmp_ep_mat_eva_stu_env_tim_alls) {
      for await (let tmp_map_eva of tmp_ep_mat_eva_stu_env_tim_alls) {
        this.$restful
          .Post(`/mesh/egr-pro-mat-eva-stu-env-tim-all/`, {
            egress_profile_matter_evaluation: evaluation.id,
            study_environment: tmp_map_eva.study_environment,
            time_allocation: tmp_map_eva.time_allocation,
            hours: tmp_map_eva.hours,
          })
          .then((response) => {
            this.ep_mat_eva_stu_env_tim_all.push(response);
          });
      }
    },
    saveEvaluationTypeDefault(new_evaluation, evaluation_type) {
      if (evaluation_type) {
        if (new_evaluation.evaluation_type_default == null) {
          this.$restful
            .Post("/evaluations2/evaluation-type-default/", evaluation_type)
            .then((response) => {
              this.$store.commit(
                "MUTATE_CHANGE_NEW_EVALUATION_TYPE_BY_NEW_EVALUATION",
                response
              );
            });
        } else {
          this.$restful
            .Put(
              `/evaluations2/evaluation-type-default/${evaluation_type.id}/`,
              evaluation_type
            )
            .then((response) => {
              this.$store.commit(
                "MUTATE_CHANGE_NEW_EVALUATION_TYPE_BY_NEW_EVALUATION",
                response
              );
            });
        }
      }
    },
    async bulkCreateTest(test_list, new_evaluation) {
      for await (let test of test_list) {
        if (isNaN(test.id)) {
          test.evaluation = new_evaluation.id;
          test.title = new_evaluation.title;
          await this.$store
            .dispatch(names.POST_NEW_TEST, test)
            .then((new_test) => {
              this.saveTestType({
                ...new_test,
                instrument_type: test.instrument_type,
              });
            });
        } else if (!test.delete_test) {
          await this.$store
            .dispatch(names.PATCH_NEW_TEST, {
              new_test_id: test.id,
              item: {
                evaluation_situation: test.evaluation_situation,
                weighing: test.weighing,
                examiner: test.examiner,
                work_type: test.work_type,
              },
            })
            .then((new_test) => {
              this.saveTestType({
                ...new_test,
                instrument_type: test.instrument_type,
              });
            });
        } else if (test.delete_test) {
          await this.$store.dispatch(names.DELETE_NEW_TEST, test.id);
        }
      }
    },
    saveTestType(new_test) {
      if (new_test.test_type_default == null) {
        this.$restful
          .Post("/evaluations2/test-type-default/", {
            test: new_test.id,
            evaluative_agent: new_test.instrument_type.evaluative_agent,
          })
          .then((response) => {
            this.$store.commit(
              "MUTATE_CHANGE_NEW_TEST_TYPES_BY_NEW_TEST",
              response
            );
          });
      } else {
        this.$restful
          .Put(
            `/evaluations2/test-type-default/${new_test.test_type_default}/`,
            {
              test: new_test.id,
              evaluative_agent: new_test.instrument_type.evaluative_agent,
            }
          )
          .then((response) => {
            this.$store.commit(
              "MUTATE_CHANGE_NEW_TEST_TYPES_BY_NEW_TEST",
              response
            );
          });
      }
    },
    deleteEvaluation(id, type) {
      const evaluation_type = this.epm_evaluation_types.find(
        (x) => x.id == type
      );
      let msg = "";
      if (evaluation_type) {
        if (type == 4) msg = "la " + evaluation_type.value.toLowerCase();
        else msg = "la evaluación " + evaluation_type.value.toLowerCase();
      }
      this.$swal({
        title: `¿Está seguro de que desea eliminar ${msg}?`,
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.value) {
          this.$restful
            .Delete(`/teaching/egress-profile-matter-evaluation/${id}/`)
            .then(() => {
              this.$emit("fetch_ep_mat_eva_stu_env_tim_all");
              const index = this.profile_matters_evaluations.findIndex(
                (x) => x.id == id
              );
              if (index != -1) {
                if (
                  this.profile_matters_evaluations[index].evaluation == null
                ) {
                  this.profile_matters_evaluations.splice(index, 1);
                  this.$emit("delete_profile_matters_evaluation", id);
                } else
                  this.$store
                    .dispatch(
                      names.DELETE_NEW_EVALUATION,
                      this.profile_matters_evaluations[index].evaluation
                    )
                    .then(() => {
                      this.profile_matters_evaluations.splice(index, 1);
                      this.$emit("delete_profile_matters_evaluation", id);
                    });
              }
            });
        }
      });
    },
    updatedEvaluation(evaluation, tests_list) {
      this.$restful
        .Put(
          `/teaching/egress-profile-matter-evaluation/${evaluation.id}/`,
          evaluation
        )
        .then((response) => {
          if (response.evaluation == null && response.type != 4) {
            this.createdNewEvaluation(response);
          } else {
            if (response.evaluation != null) {
              this.$store
                .dispatch(names.PATCH_NEW_EVALUATION, {
                  new_evaluation_id: response.evaluation,
                  item: {
                    title: response.name,
                    description: response.description,
                    weighing: response.weighing,
                  },
                })
                .then((new_evaluation) => {
                  this.$store.commit(
                    names.MUTATE_NEW_EVALUATION,
                    new_evaluation
                  );
                  this.bulkCreateTest(tests_list, new_evaluation);
                });
            }
            const index = this.profile_matters_evaluations.findIndex(
              (x) => x.id == response.id
            );
            if (index != -1) {
              this.profile_matters_evaluations.splice(index, 1, response);
              this.$bvModal.hide(`edit-evaluation-modal-${response.id}`);
            }
          }
        });
    },
  },
  mounted() {},
  created() {},
};
</script>

<style scoped src="@/utils/rich_text_editor.css">
</style>
<style scoped>
.td-btn-wrapper {
  text-align: -webkit-center;
}
.description-evaluation {
  max-height: 200px;
  overflow-y: auto;
}
.div-href {
  color: #007bff;
}
.div-href:hover {
  color: #0057b1 !important;
  text-decoration: underline;
  cursor: pointer;
}
table th {
  vertical-align: middle;
  background-color: var(--primary-color);
  color: white;
  font-size: var(--primary-font-size);
  padding: 2px 2px;
}
.evaluation-header {
  background: var(--primary-color) !important;
  border: 1px solid #000 !important;
}
.evaluation-button-div {
  float: right;
  /* padding-right: 1rem; */
}
table td {
  border: 1px solid black;
  text-align: left;
  vertical-align: middle;
  font-size: var(--secondary-font-size);
  padding: 2px 2px;
}
.grabbable {
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}
.grabbable:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}
.ec-badge-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
.ec-micro-badge {
  font-size: var(--thirth-font-size);
  text-align: center;
  width: fit-content !important;
  margin-right: 0.3em;
}
@media print {
  .ec-micro-badge {
    border: none;
  }
  .description-evaluation {
    overflow-y: none !important;
    max-height: 100% !important;
  }
}
</style>